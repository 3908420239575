import { Clipboard, ClipboardModule } from "@angular/cdk/clipboard";
import { A11yModule } from "@angular/cdk/a11y";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RoamToastrService, TASK_TOASTR_MESSAGE } from "../../+data-access";

export interface ShareLinkDialogData {
  title: string;
  link: string;
}

@Component({
  standalone: true,
  selector: "app-share-link-dialog",
  styleUrls: ["../dialog.style.scss", "./share-link-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    ClipboardModule,
    A11yModule,
  ],
  template: `
    <div class="dialog-header">
      <h2 mat-dialog-title>
        {{ data.title }}
      </h2>
    </div>
    <button mat-icon-button class="close-button" (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-dialog-content>
      <p class="label">Share link</p>
      <div class="flex-group">
        <mat-form-field appearance="outline">
          <input
            matInput
            type="search"
            placeholder="Search"
            [value]="data.link"
          />
        </mat-form-field>
        <button mat-stroked-button (click)="copy()" class="copy-button">
          Copy
        </button>
        <button mat-stroked-button class="submit-button">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </mat-dialog-content>
  `,
})
export class ShareLinkDialogComponent {
  protected toastr = inject(RoamToastrService);
  protected clipboard = inject(Clipboard);

  copy(): void {
    this.clipboard.copy(this.data.link);
    this.toastr.info(TASK_TOASTR_MESSAGE.COPIED_TO_CLIPBOARD);
  }

  sendLink(): void {
    alert("TODO: send to email...");
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly data: ShareLinkDialogData,
    readonly dialogRef: MatDialogRef<ShareLinkDialogData>,
  ) {}
}
